<template>
  <div :class="{'page-wrapper': isDocsPage}">
    <Header :class="addUniqueClass"/>
    <main :class="{'page-gradient': !isDocsPage}">
      <router-view v-slot="{ Component }">
        <transition name="fadeLong" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
    <Footer />
    <div :class="{'spacer': isDocsPage, 'spacer-mobile': true}"></div>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  computed: {
    addUniqueClass () {
      return this.$route.meta.header
    },
    isDocsPage () {
      return this.$route.name === 'agreement' || this.$route.name === 'privacy';
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  margin-bottom: auto; /* Отступ снизу перед футером */
}

footer {
  margin-top: auto; /* Отступ сверху перед футером */
}

.spacer {
  height: 50px; /* Пустое пространство после футера, можно настроить */
  flex-shrink: 0;
}

@media (max-width: 768px) {
.spacer-mobile {
  height: 50px; /* Пустое пространство после футера, можно настроить */
  flex-shrink: 0;
}
}
</style>
